import { StaticImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Container } from '../components/container'
import { Layout } from '../components/layout'
import { SlideCaption, Slider } from '../components/slider'
import circleCheck from '../assets/images/circle_check.svg'

const ServicesPage: FC = () => {
  const { t } = useTranslation('services')

  return (
    <Layout title={t('documentTitle')} activePage="/services/">
      <Slider interval={5000}>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-services1.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.first')}</small>
          </SlideCaption>
        </>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-services2.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.second')}</small>
          </SlideCaption>
        </>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-services3.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>
            {t('slider.common')}
            <small>{t('slider.third')}</small>
          </SlideCaption>
        </>
      </Slider>

      <BlueSection>
        <Container>
          <ul>
            <li>{t('checklist.first')}</li>
            <li>{t('checklist.second')}</li>
            <li>{t('checklist.third')}</li>
            <li>{t('checklist.fourth')}</li>
            <li>{t('checklist.fifth')}</li>
          </ul>
        </Container>
      </BlueSection>

      <DetailsContainer>
        <div className="title">
          <h2>{t('details.title')}</h2>
          <p>{t('details.subtitle')}</p>
        </div>
        <div className="list-container">
          {(
            t('details.columns') as Array<{ title: string; details: string[] }>
          ).map(({ title, details }, index) => (
            <section key={index}>
              <h3>{title}</h3>
              <ul>
                {details.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))}
              </ul>
            </section>
          ))}
        </div>
      </DetailsContainer>
    </Layout>
  )
}

export default ServicesPage

const BlueSection = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.brand};
    color: ${theme.colors.white};

    ul {
      padding-top: 1rem;
      padding-bottom: 1rem;

      ${theme.media.min('tablet_w')} {
        padding-top: 1rem;
        padding-bottom: 1rem;
        column-count: 2;
      }

      li {
        background: url(${circleCheck}) no-repeat left top;
        padding-left: 1.875rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  `}
`

const DetailsContainer = styled(Container)`
  ${({ theme }) => css`
    .title {
      text-align: center;

      h2 {
        font-family: ${theme.typography.raleway};
        font-size: 1.5rem;
        font-weight: 600;
        color: ${theme.colors.brand};
        margin-bottom: 0;
        line-height: 1.33;
      }

      p {
        color: ${theme.colors.brand};
      }
    }

    .list-container {
      display: flex;
      flex-direction: column;

      ${theme.media.max('tablet')} {
        max-width: 25rem;
        margin: 0 auto;
      }
      ${theme.media.min('tablet')} {
        flex-direction: row;
        justify-content: space-between;
        align-items: top;
      }

      section {
        margin-top: 2.5rem;

        ${theme.media.min('tablet')} {
          width: 28%;
        }

        h3 {
          font-family: ${theme.typography.raleway};
          font-size: 1.2rem;
          font-weight: 600;
          color: ${theme.colors.brand};
          margin-bottom: 0;
          line-height: 1.33;
        }

        ul {
          padding-top: 1.5rem;

          li {
            font-size: 0.9rem;
            padding: 0;
            line-height: 1.3;
            list-style-type: disc;

            // ${theme.media.min('tablet_w')} {
            //   width: 90%;

            //   &:nth-of-type(6) {
            //     border: none;
            //     padding: 0;
            //   }
            // }
          }
        }
      }
    }
  `}
`
